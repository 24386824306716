import Swiper, { Navigation, Pagination } from 'swiper';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

Swiper.use([Navigation, Pagination]);

export default {
  init() {
    const modal = document.getElementById('gallery-modal');
    const modalClose = document.querySelectorAll('.modal__close');
    const projecImages = document.querySelectorAll('[data-imageid]');
    const modalGalleryHolder = document.querySelector(
      '.gallery-slider__wrapper'
    );
    const swiper: any = new Swiper('.gallery-slider', {
      init: false,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.gallery-slider__next',
        prevEl: '.gallery-slider__prev'
      }
    });

    function openModal(target: HTMLElement) {
      disableBodyScroll(target);
      target.classList.add('is-open');
    }

    function addHash(hs: number) {
      window.location.hash = `imgid-${hs}`;
    }

    if (projecImages && modalGalleryHolder) {
      projecImages.forEach(img => {
        const imgUrl = img.querySelector('img');
        const index = (img as HTMLElement).dataset.imageid;
        const slideMarkup: any = `
            <img src="${imgUrl.getAttribute('src')}" alt="${imgUrl.getAttribute(
          'alt'
        )}" />
        `;
        const markup = document.createElement('div');
        markup.classList.add('gallery-slider__slide');
        markup.classList.add('swiper-slide');
        markup.setAttribute('data-slideid', `${index}`);
        markup.innerHTML = slideMarkup;
        modalGalleryHolder.insertAdjacentElement('beforeend', markup);

        img.addEventListener('click', () => {
          swiper.slideTo(+index - 1);
          addHash(+index);
          openModal(modal);
        });
      });
      swiper.init();
    }

    swiper.on('slideChange', function() {
      addHash(swiper.activeIndex + 1);
    });

    modalClose.forEach(singleClose => {
      singleClose.addEventListener('click', () => {
        clearAllBodyScrollLocks();
        singleClose.closest('.modal').classList.remove('is-open');
      });
    });

    if (window.location.hash) {
      if (window.location.hash.split('-')[0] === '#imgid') {
        const slideNumber = window.location.hash.split('-')[1];
        swiper.slideTo(+slideNumber - 1);
        openModal(modal);
      }
    }
  }
};
