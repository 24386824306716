import inView from 'in-view';

export default {
  init() {
    inView('.js-reveal').on('enter', el => {
      el.style.opacity = 1;
      el.style.transform = 'translateY(0px)';
    });
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
  }
};
