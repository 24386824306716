import autosize from 'autosize';

export default {
  inputsEvents(): void {
    const textInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '[data-text]'
    );
    const fileInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '[data-file]'
    );
    const checkInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '[data-check]'
    );
    const selectors: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '[data-select]'
    );

    autosize(document.querySelectorAll('textarea'));

    for (let i = 0; i < textInputs.length; i += 1) {
      textInputs[i].addEventListener('change', function() {
        if (this.value.trim().length > 0) {
          this.parentElement.classList.add('full');
        } else {
          this.parentElement.classList.remove('full');
        }

        if (
          this.closest('.form-input').classList.contains(
            'form-input--has-error'
          )
        ) {
          this.closest('.form-input').classList.remove('form-input--has-error');
        }
      });
    }

    for (let i = 0; i < textInputs.length; i += 1) {
      textInputs[i].addEventListener('keydown', function() {
        if (this.value.trim().length > 0) {
          this.parentElement.classList.add('full');
        } else {
          this.parentElement.classList.remove('full');
        }

        if (
          this.closest('.form-input').classList.contains(
            'form-input--has-error'
          )
        ) {
          this.closest('.form-input').classList.remove('form-input--has-error');
        }
      });
    }

    for (let i = 0; i < textInputs.length; i += 1) {
      textInputs[i].addEventListener('keyup', function() {
        if (this.value.trim().length > 0) {
          this.parentElement.classList.add('full');
        } else {
          this.parentElement.classList.remove('full');
        }

        if (
          this.closest('.form-input').classList.contains(
            'form-input--has-error'
          )
        ) {
          this.closest('.form-input').classList.remove('form-input--has-error');
        }
      });
    }

    for (let i = 0; i < textInputs.length; i += 1) {
      textInputs[i].addEventListener('focusin', function() {
        this.parentElement.classList.add('focus');
      });
    }

    for (let i = 0; i < selectors.length; i += 1) {
      selectors[i].addEventListener('change', function() {
        this.closest('.form-input').classList.remove('form-input--has-error');
      });
    }

    for (let i = 0; i < textInputs.length; i += 1) {
      textInputs[i].addEventListener('focusout', function() {
        this.parentElement.classList.remove('focus');
      });
    }

    for (let i = 0; i < checkInputs.length; i += 1) {
      checkInputs[i].addEventListener('change', function() {
        if (
          this.closest('.form-input').classList.contains(
            'form-input--has-error'
          ) &&
          this.checked
        ) {
          this.closest('.form-input').classList.remove('form-input--has-error');
        }
      });
    }
  }
};
