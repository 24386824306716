export default {
  init() {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav');
    const header = document.querySelector('.header');

    burger.addEventListener('click', () => {
      burger.classList.toggle('burger--is-open');
      nav.classList.toggle('nav--is-open');
      header.classList.toggle('menu-is-open');
    });
  }
};
