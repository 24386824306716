/* eslint-disable */
import axios from 'axios';

export default {
  initForm() {
    const asyncForm = document.querySelectorAll('[data-async]');

    for (let i = 0; i < asyncForm.length; i += 1) {
      asyncForm[i].addEventListener('submit', function(e) {
        e.preventDefault();
        const $form = this;
        const options = {
          method: $form.dataset.method,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: new FormData($form),
          url: $form.dataset.action
        };

        $form.classList.add('is-loading');

        axios(options)
          .then(response => {
            console.log('Success response:', response.data);
            if (response.data.status === 'badfields') {
              setTimeout(function() {
                $form.classList.remove('is-loading');
                badFields($form, response.data);
              }, 500);
            } else if (response.data.status === 'success') {
              if ($form.classList.contains('cookie__form')) {
                $form.closest('.cookie').classList.remove('is-shown');
              } else {
                setTimeout(function() {
                  $form.classList.remove('is-loading');
                  showMessage($form, response.data);
                  resetForm($form);
                }, 1000);
              }
            } else if (response.data.status === 'error') {
              setTimeout(function() {
                $form.classList.remove('is-loading');
                showMessage($form, response.data);
              }, 1000);
            }
          })
          .catch(error => {
            console.log('Error response:', error);
          });
      });
    }

    const badFields = (f, resp) => {
      resp.fields.map((field, i) => {
        const targetField = f.querySelector(`[name="${field.name}"]`);
        const targetContainer = targetField.closest('.form-input');
        targetContainer.querySelector('.form-input__field-message').innerHTML =
          field.errorMessage;
        targetContainer.classList.add('form-input--has-error');
      });
    };

    const showMessage = (f, resp) => {
      const title = document.getElementById('response-title');
      const subtitle = document.getElementById('response-subtitle');
      const button = document.getElementById('response-btn');
      const container = document.getElementById('form-response');
      title.innerHTML = resp.output.title;
      subtitle.innerHTML = resp.output.subtitle;
      button.innerHTML = resp.output.btn;
      container.classList.add('is-visible');
    };

    const resetForm = f => {
      const inputs = f.querySelectorAll('.form-input');
      const textareas = f.querySelectorAll('textarea');
      const containers = document.querySelectorAll('.js-select-container');

      if (containers.length > 0) {
        containers.forEach(container => {
          const selectors = container.querySelectorAll('.js-select');

          selectors.forEach(selector => {
            selector.classList.remove('is-selected');
          });
        });
      }

      f.reset();
      for (let i = 0; i < textareas.length; i += 1) {
        textareas[i].style.height = 50 + 'px';
      }
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].classList.remove('full');
        inputs[i].classList.remove('form-input--has-error');
      }
    };

    const responseClose = document.querySelector('.js-close-response');
    if (responseClose) {
      responseClose.addEventListener('click', function() {
        this.closest('#form-response').classList.remove('is-visible');
      });
    }
  }
};
