import '../scss/style.scss';
import { nodeListForEach } from './addons/polyfills';
import Swiper from './components/Swiper';
import FormInputs from './components/FormInputs';
import FormJs from './components/Form';
import HeaderColor from './components/HeaderColor';
import Modal from './components/Modal';
import Fadeins from './components/Fadeins';
import Burger from './components/Burger';

nodeListForEach();

const inits = (): void => {
  Swiper.hero();
  FormInputs.inputsEvents();
  FormJs.initForm();
  Modal.init();
  Fadeins.init();
  Burger.init();
};

const onScroll = (e: Event): void => {
  HeaderColor.init();
};

const onResize = (e: Event): void => {
  onScroll(e);
};

const onLoad = (e: Event): void => {
  inits();
  onResize(e);
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
