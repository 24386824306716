import Swiper, { Pagination } from 'swiper';

Swiper.use([Pagination]);

export default {
  hero() {
    const mySwiper = new Swiper('.hero__slider', {
      pagination: {
        el: '.hero__pagination'
      }
    });
  }
};
