/* eslint-disable */
export default {
  init() {
    const header = document.querySelector('.header');
    const backgrounds = document.querySelectorAll('[data-bg]');

    if (header && backgrounds.length > 0) {
      backgrounds.forEach(element => {
        const elRect = element.getBoundingClientRect();
        const top = elRect.top;
        const bottom = elRect.bottom;
        const bckRect = header.getBoundingClientRect();
        const bckTop = bckRect.top;
        const bckBottom = bckRect.bottom;

        if (bckTop + 10 > top && bckTop < bottom) {
          header.classList.add('on-white-bg');
        } else {
          header.classList.remove('on-white-bg');
        }
      });
    }
  }
};
